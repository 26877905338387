var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    {
      staticClass: "d-flex flex-column",
      attrs: { id: "cardDash", elevation: "0" },
    },
    [
      _c("FilterEncapsulated", {
        ref: "filter",
        attrs: { disabled: _vm.isFiltersDisabled },
        on: {
          applyFilter: _vm.applyFilter,
          clearFilters: _vm.clearFilter,
          closeFilterDialog: _vm.closeFilterDialog,
        },
        scopedSlots: _vm._u([
          {
            key: "filters",
            fn: function () {
              return [
                _c("SelectMultiple", {
                  attrs: {
                    id: "selectCnpj",
                    label: _vm.txt.cnpj,
                    items: _vm.selectCnpj,
                    disabled: _vm.isFiltersDisabled,
                    hasSelectAll: true,
                  },
                  model: {
                    value: _vm.filters.cnpj,
                    callback: function ($$v) {
                      _vm.$set(_vm.filters, "cnpj", $$v)
                    },
                    expression: "filters.cnpj",
                  },
                }),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c("GraphsContainer", {
        staticClass: "mb-4",
        attrs: {
          title: _vm.txt.graphsContainerTitle,
          disabled: _vm.loadingGraphs,
        },
        scopedSlots: _vm._u([
          {
            key: "graphs",
            fn: function () {
              return [
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { staticClass: "pr-4", attrs: { cols: "9" } },
                      [
                        _c(
                          "v-card",
                          {
                            staticClass: "pa-0 analysis-position-inherit",
                            attrs: { height: "100%" },
                          },
                          [
                            _c(
                              "v-tabs",
                              {
                                model: {
                                  value: _vm.autoAnalysis,
                                  callback: function ($$v) {
                                    _vm.autoAnalysis = $$v
                                  },
                                  expression: "autoAnalysis",
                                },
                              },
                              [
                                _c("v-tabs-slider"),
                                _c(
                                  "v-tab",
                                  {
                                    attrs: {
                                      value: "auto_analysis",
                                      id: "auto_analysis_tab",
                                    },
                                  },
                                  [
                                    _c("div", { staticClass: "tab-text" }, [
                                      _vm._v("Análise automática"),
                                    ]),
                                  ]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "v-tabs-items",
                              {
                                staticClass: "analysis-position-inherit",
                                model: {
                                  value: _vm.autoAnalysis,
                                  callback: function ($$v) {
                                    _vm.autoAnalysis = $$v
                                  },
                                  expression: "autoAnalysis",
                                },
                              },
                              [
                                _c(
                                  "v-tab-item",
                                  { staticClass: "analysis-position-inherit" },
                                  [
                                    _c(
                                      "v-container",
                                      {
                                        staticClass:
                                          "pa-4 analysis-intern-card-container",
                                        attrs: { fluid: "" },
                                      },
                                      [
                                        _c(
                                          "v-row",
                                          [
                                            _c("v-col"),
                                            _c(
                                              "v-col",
                                              {
                                                staticClass: "text-end",
                                                attrs: {
                                                  cols: "1",
                                                  "align-self": "end",
                                                },
                                              },
                                              [
                                                _c("IconTooltip", {
                                                  staticClass:
                                                    "graphs-container-helper",
                                                  attrs: {
                                                    icon: "help",
                                                    iconClass:
                                                      "material-symbols-outlined",
                                                    tooltipText:
                                                      _vm.txt
                                                        .helperAutoAnalysys,
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c("GraphAutoAnalysis", {
                                          staticClass: "mb-4",
                                          attrs: { byStatus: _vm.byStatus },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      { staticClass: "pl-0", attrs: { cols: "3" } },
                      [
                        _c(
                          "v-card",
                          { staticClass: "analysis-intern-card" },
                          [
                            _c(
                              "v-row",
                              { staticClass: "ma-1" },
                              [
                                _c(
                                  "v-col",
                                  {
                                    staticClass: "pa-0 title-graph",
                                    attrs: { cols: "11" },
                                  },
                                  [_vm._v("Visão geral por análise")]
                                ),
                                _c(
                                  "v-col",
                                  { staticClass: "pa-0", attrs: { cols: "1" } },
                                  [
                                    _c("IconTooltip", {
                                      staticClass: "graphs-container-helper",
                                      attrs: {
                                        icon: "help",
                                        iconClass: "material-symbols-outlined",
                                        tooltipText:
                                          _vm.txt.helperGeneralVision,
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c("GraphDonut", {
                              attrs: {
                                options: _vm.emitedAnalysysGraphOptions,
                                series: _vm.emitedAnalysisGraphSeries,
                                height: "350px",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c("TableAutoAnalysis"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }