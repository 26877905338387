<template>
  <v-card class="d-flex flex-column" id="cardDash" elevation="0">
    <FilterEncapsulated
      ref="filter"
      :disabled="isFiltersDisabled"
      @applyFilter="applyFilter"
      @clearFilters="clearFilter"
      @closeFilterDialog="closeFilterDialog"
    >
      <template v-slot:filters>
        <SelectMultiple
          id="selectCnpj"
          :label="txt.cnpj"
          v-model="filters.cnpj"
          :items="selectCnpj"
          :disabled="isFiltersDisabled"
          :hasSelectAll="true"
        />
      </template>
    </FilterEncapsulated>
    <GraphsContainer :title="txt.graphsContainerTitle" :disabled="loadingGraphs" class="mb-4">
      <template v-slot:graphs>
        <v-row>
          <v-col cols="9" class="pr-4">
            <v-card class="pa-0 analysis-position-inherit" height="100%">
              <v-tabs v-model="autoAnalysis">
                <v-tabs-slider></v-tabs-slider>
                <v-tab value="auto_analysis" id="auto_analysis_tab">
                  <div class="tab-text">Análise automática</div>
                </v-tab>
              </v-tabs>
              <v-tabs-items v-model="autoAnalysis" class="analysis-position-inherit">
                <v-tab-item class="analysis-position-inherit">
                  <v-container fluid class="pa-4 analysis-intern-card-container">
                    <v-row>
                      <v-col> </v-col>
                      <v-col cols="1" align-self="end" class="text-end">
                        <IconTooltip
                          icon="help"
                          class="graphs-container-helper"
                          iconClass="material-symbols-outlined"
                          :tooltipText="txt.helperAutoAnalysys"
                        ></IconTooltip>
                      </v-col>
                    </v-row>
                    <GraphAutoAnalysis :byStatus="byStatus" class="mb-4" />
                  </v-container>
                </v-tab-item>
              </v-tabs-items>
            </v-card>
          </v-col>
          <v-col cols="3" class="pl-0">
            <v-card class="analysis-intern-card">
              <v-row class="ma-1"
                ><v-col cols="11" class="pa-0 title-graph">Visão geral por análise</v-col>
                <v-col cols="1" class="pa-0"
                  ><IconTooltip
                    icon="help"
                    class="graphs-container-helper"
                    iconClass="material-symbols-outlined"
                    :tooltipText="txt.helperGeneralVision"
                  >
                  </IconTooltip></v-col
              ></v-row>

              <GraphDonut :options="emitedAnalysysGraphOptions" :series="emitedAnalysisGraphSeries" height="350px" />
            </v-card>
          </v-col>
        </v-row>
      </template>
    </GraphsContainer>
    <TableAutoAnalysis></TableAutoAnalysis>
  </v-card>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import { cloneDeep } from 'lodash';

export default {
  name: 'TabGeneralVision',
  components: {
    FilterEncapsulated: () => import('../../commons/FilterEncapsulated.vue'),
    SelectMultiple: () => import('../../commons/SelectMultiple.vue'),
    GraphsContainer: () => import('../../commons/GraphsContainer.vue'),
    GraphLinePercentual: () => import('../../commons/GraphLinePercentual.vue'),
    GraphDonut: () => import('../../commons/GraphDonut.vue'),
    IconTooltip: () => import('../../commons/IconTooltip.vue'),
    TableAutoAnalysis: () => import('./TableAutoAnalysis.vue'),
    GraphAutoAnalysis: () => import('./GraphAutoAnalysis.vue'),
  },
  data() {
    return {
      txt: {
        cnpj: 'Empregador ',
        graphsContainerTitle:
          '<strong>Gráficos</strong> por análise automática, classificação de análise e visão geral por situação',
        helperAutoAnalysys:
          'Este gráfico pode receber filtros por todas as opções disponíveis no botão Filtro, com exceção da opção Análise automática, pois o gráfico já traz informações detalhadas sobre essa análise.',
        helperGeneralVision:
          'Detalhamento da legenda: Em análise são as rubricas que aparecem pela primeira vez em Agnes, foram objeto de esclarecimento ou sofreram alguma modificação após a análise. ',
      },
      filters: {
        cnpj: [],
      },
      autoAnalysis: 0,
    };
  },
  created() {
    this.init();
  },
  computed: {
    ...mapGetters({
      filtersSaved: 'esocial/esocialRubricas/getFiltersSaved',
      loadingFilter: 'esocial/esocialRubricas/getLoadingFilters',
      selectCnpj: 'esocial/esocialRubricas/getSelectCnpj',
      loadingGraphs: 'esocial/esocialRubricas/getLoadingGraphs',
      dataGraphs: 'esocial/esocialRubricas/getDataGraphs',
    }),
    isFiltersDisabled() {
      return this.loadingFilter;
    },
    byStatus() {
      if (this.dataGraphs) {
        return this.dataGraphs.byStatus;
      } else {
        return {
          duplicado: 0,
          invalido: 0,
          divergente: 0,
          expirado: 0,
          obrigatorioESemPreenchimento: 0,
        };
      }
    },
    emitedAnalysisGraphSeries() {
      let emAnalisePercent = this.dataGraphs.byAnalysis ? this.dataGraphs.byAnalysis.emAnalisePercent : 0;
      let disponivelParaUsoPercent = this.dataGraphs.byAnalysis
        ? this.dataGraphs.byAnalysis.disponivelParaUsoPercent
        : 0;
      return [emAnalisePercent, disponivelParaUsoPercent];
    },
    emitedAnalysysGraphOptions() {
      let options = {
        height: '100%',
        plotOptions: {
          pie: {
            donut: {
              size: '80%',
            },
          },
        },
        legend: {
          show: true,
          position: 'bottom',
          onItemHover: {
            highlightDataSeries: false,
          },
          onItemClick: {
            toggleDataSeries: false,
          },
        },
        colors: ['#E5D545', '#442d96'],
      };
      options['labels'] = [
        `Em análise (${this.dataGraphs.byAnalysis ? this.dataGraphs.byAnalysis.emAnalise : 0}/${
          this.dataGraphs.byAnalysis ? this.dataGraphs.byAnalysis.total : 0
        })`,
        `Analisadas (${this.dataGraphs.byAnalysis ? this.dataGraphs.byAnalysis.disponivelParaUso : 0}/${
          this.dataGraphs.byAnalysis ? this.dataGraphs.byAnalysis.total : 0
        })`,
      ];

      return options;
    },
  },
  methods: {
    ...mapActions('esocial/esocialRubricas', ['init', 'clearFilters', 'applyFilters']),
    applyFilter() {
      this.applyFilters(this.filters);
    },
    clearFilter() {
      this.clearFilters();
    },
    closeFilterDialog() {
      this.filters = cloneDeep(this.filtersSaved);
    },
  },
};
</script>

<style lang="scss" scoped>
@import './src/design/variables.scss';
.cardDash {
  width: 1792px;
  max-width: 1792px;
}
.analysis-position-inherit {
  position: inherit;
}
.analysis-intern-card-container {
  position: inherit;

  .analysis-intern-card-row {
    position: inherit;

    .analysis-intern-card {
      background-color: $neutral-color-low-medium !important;
      position: inherit;
    }
  }
}

.analysis-card-border-fix {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
  border-left-width: 0 !important;
  border-right-width: 0 !important;
  border-bottom-width: 0 !important;
}
.graphs-container-helper {
  margin-left: 5px;
  cursor: help;
  font-size: $font-size-sm;
}
.label-graph {
  color: #52526b;
  font-size: 14px;
  font-family: Roboto;
  font-weight: 400;
  line-height: 18.62px;
  letter-spacing: 0.14px;
  word-wrap: break-word;
}
.title-graph {
  color: var(--ag-capital-brand-tokens-primary-medium, #442d96);
  text-align: left;
  font-family: var(--fontes-regular-sm-font-family, 'Roboto-Regular', sans-serif);
  font-size: var(--fontes-regular-sm-font-size, 20px);
  line-height: var(--fontes-regular-sm-line-height, 150%);
  font-weight: var(--fontes-regular-sm-font-weight, 400);
  position: relative;
  flex: 1;
}
</style>
